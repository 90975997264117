/* all files are imported */

@import url('./items/Buttons/Buttons.css');
@import url('./items/Accordion/Accordion.css');
@import url('./items/Accordion/Accordion.css');










.common_ui{
    position: relative;
}

.common_ui  h1 {
   text-align: center;
   color: #333;
   margin-top: 60px;
   margin-bottom: 10px;
   font-weight: 600;
 }

.common_ui ._common_container{
    padding: 35px 40px;
    box-shadow: 1px 1px 6px #6f6f6f;
    margin: 20px;
}
  
  
 .common_ui h2 {
    color: #444;
    margin-bottom: 10px;
  }
  
 .common_ui .button-group {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 20px;
    align-items: center;
  }
  






  /* Buttons */
  .ocl-btn-1 {
    border: 1px solid var(--themeColor);
    border-radius: 22px;
    color: white;
    background-color: var(--themeColor);
    font-weight: 400;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    transform-origin: top right;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    height: 35px;
    padding: 10px 0px;
  }
  
  .ocl-btn-1::after {
    background-color: white;
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  
  .ocl-btn-1:hover::after {
    transform: translate(0, 0);
  }
  
  .ocl-btn-1:hover {
    border: 1px solid transparent;
    color: var(--themeColor);
  }
  
  .ocl-btn-1.disabled {
    pointer-events: none;
  }
  
  .ocl-btn-2 {
    border: 1px solid var(--themeColor);
    border-radius: 22px;
    color: var(--themeColor);
    background-color: white;
    font-weight: 400;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    font-size: 15px;
    height: 35px;
  }
  
  .ocl-btn-2::after {
    background-color: var(--themeColor);
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  
  .ocl-btn-2:hover::after {
    transform: translate(0, 0);
  }
  
  .ocl-btn-2:hover {
    border: 1px solid transparent;
    color: white;
  }
  
  .ocl-btn-3 {
    border: 1px solid #D34949;
    border-radius: 22px;
    color: #D34949;
    background-color: white;
    font-weight: 500;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    transition: 0.3s transform ease-in-out;
    will-change: transform;
    z-index: 0;
    width: auto;
    user-select: none;
    text-align: center;
    font-size: 15px;
    height: 35px;
  }
  
  .ocl-btn-3::after {
    background-color: #D34949;
    border-radius: 22px;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
    position: absolute;
    left: 0;
    top: 0;
    transform: translate(-100%, 0) rotate(0deg);
    transform-origin: top;
    transition: 0.3s transform ease-out;
    will-change: transform;
    z-index: -1;
  }
  
  .ocl-btn-3:hover::after {
    transform: translate(0, 0);
  }
  
  .ocl-btn-3:hover {
    border: 1px solid transparent;
    color: white;
  }
  
  /* Button Sizes */
  .ocl-btn-1.small, .ocl-btn-2.small, .ocl-btn-3.small {
    font-size: 12px;
    height: 25px;
    padding: 0px 20px;
  }
  
  .ocl-btn-1.medium, .ocl-btn-2.medium, .ocl-btn-3.medium {
    font-size: 15px;
    height: 35px;
    padding: 0px 30px;
  }
  
  .ocl-btn-1.large, .ocl-btn-2.large, .ocl-btn-3.large {
    font-size: 18px;
    height: 45px;
    padding: 0px 50px;
  }


  /* ======================= Check box  =================== */

  .ocl-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    font-size: 16px;
}

.ocl-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.ocl-checkbox .checkmark {
    height: 20px;
    width: 20px;
    background-color: #eee;
    border: 2px solid #007BFF;
    border-radius: 4px;
    margin-right: 10px;
    transition: background-color 0.3s, border-color 0.3s;
    position: relative; /* Ensure positioning for the tick pseudo-element */
}

.ocl-checkbox input:checked ~ .checkmark {
    background-color: #007BFF;
}

.ocl-checkbox .checkmark::after {
    content: '';
    position: absolute;
    display: none;
    left: 7px;
    top: 3px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.ocl-checkbox input:checked ~ .checkmark::after {
    display: block;
}

.ocl-checkbox-2 .checkmark {
    border-color: #D34949;
}

.ocl-checkbox-2 input:checked ~ .checkmark {
    background-color: #D34949;
}

.ocl-checkbox-2 .checkmark::after {
    border-color: white; /* Ensure the tick is visible on the red background */
}




/* /// ============================ toggles ============================ */

/* Common Container */
.toggle-group {
  display: flex;
  gap: 20px;
  align-items: center;
  margin-bottom: 20px;
}

.ocl-toggle {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 30px;
}

.ocl-toggle input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Fancy Toggle Style */
.ocl-toggle.fancy .slider {
  background: linear-gradient(135deg, #ff7eb3, #ff758c);
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}

.ocl-toggle.fancy .slider::before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: white;
  bottom: 3px;
  left: 3px;
  transition: 0.4s;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.ocl-toggle.fancy input:checked + .slider {
  background: linear-gradient(135deg, #34c759, #0fb272);
}

.ocl-toggle.fancy input:checked + .slider::before {
  transform: translateX(30px);
}

/* Glassmorphic Toggle Style */
.ocl-toggle.glass .slider {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(8px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 30px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: 0.4s;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.ocl-toggle.glass .slider::before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: radial-gradient(circle, #ffffff, #e5e5e5);
  bottom: 3px;
  left: 3px;
  transition: 0.4s;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
}

.ocl-toggle.glass input:checked + .slider {
  background: rgba(52, 199, 89, 0.2);
  border: 1px solid rgba(52, 199, 89, 0.3);
}

.ocl-toggle.glass input:checked + .slider::before {
  transform: translateX(30px);
  background: radial-gradient(circle, #34c759, #0fb272);
}

/* Sizes */
.ocl-toggle.small {
  width: 40px;
  height: 20px;
}

.ocl-toggle.small .slider::before {
  height: 16px;
  width: 16px;
}

.ocl-toggle.small input:checked + .slider::before {
  transform: translateX(20px);
}

.ocl-toggle.large {
  width: 80px;
  height: 40px;
}

.ocl-toggle.large .slider::before {
  height: 32px;
  width: 32px;
}

.ocl-toggle.large input:checked + .slider::before {
  transform: translateX(40px);
}



/* /// ========================= Common Table css =======================================  */

.common_setting_page{
    padding: 20px;
  }
  
  
  .common_setting_page header{
    font-size: 18px;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .common_setting_page header > div {
    width: 50%;
    display: flex;
    justify-content: end;
    align-items: center;
  }
  .common_setting_page header > div > button{
    width: 150px;
    font-size: 13px;
    margin-left: 10px;
  }
  .common_setting_page header > p{
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
  }
  
  .common_setting_container{
    margin: 20px 0px;
    max-height: calc(100vh - 175px);
    overflow: auto;
    padding-bottom: 20px;
    margin-bottom: 0;
  }
  
  
  /* ========================================= Common table css================================  */
  
  .ocl-table-standard {
    width: 100%;
    border-collapse: collapse;
  }
  .ocl-table-standard tbody tr td{
    text-align: left;
    padding: 8px 14px;
    min-width: 200px;
    font-size: 11px;
    color: black;
    z-index: 1;
    border: none;
  }
  
  .ocl-table-standard tbody tr:first-child{
    background-color: #ecf8fd;
    /* font-weight: 600; */
    position: sticky;
    top: 0;
    z-index: 100;
    color: black;
    box-shadow: 1px 1px 7px #c6c6c6;
  }
  .ocl-table-standard tbody tr:first-child td{
    color: black;
    /* font-weight: 600; */
    text-align: left;
  }
  .ocl-table-standard tbody tr:first-child td:first-child{
    background-color: #ecf8fd;
    font-weight: 600;
  }
  .ocl-table-standard tbody tr:nth-child(even) td:first-child {
    background-color: #ffffff;
  }
  
  
  .ocl-table-standard tbody tr:nth-child(even){
    background-color: #ffffff;
  }
  
  
  .ocl-table-standard tbody tr td:first-child{
    position: sticky;
    left: 0;
    z-index: 50;
    background-color: #ecf8fd;
    font-weight: 500;
    padding-left: 18px;
    text-align: left;
  }
  .ocl-table-standard tbody tr:nth-child(even) {
    background-color: #ffffff;
  }
  .ocl-table-standard tbody tr:nth-child(odd) {
    background-color: #ecf8fd;
  }
  .ocl-table-standard tbody tr:first-child {
    background-color: #ecf8fd;
  }
  
  
  .ocl-table-standard tbody tr:first-child{
    background-color: #ecf8fd;
    border-top: 1px solid #e4e4e4;
  }
  
  /* Make both rows of thead sticky */
  /* .ocl-table-standard thead tr:first-child th,
  .ocl-table-standard thead tr:nth-child(2) th {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #ecf8fd;
    box-shadow: 1px 1px 7px #c6c6c6;
  } */
  
  .ocl-table-standard thead tr:first-child th
   {
    position: sticky;
    top: 0;
    z-index: 100;
    background-color: #ecf8fd;
    border-bottom: 1px solid #c6c6c6;
    box-shadow: none;
  }
  .ocl-table-standard thead tr:nth-child(2) th {
    top: 40px; /* Adjust this according to the height of the first row */
  }
  
  .ocl-table-standard tbody .operations >i{
    margin-right: 15px;
    cursor: pointer;
    font-size: 12px;
    transition: transform 0.3s ease;
    display: inline-block;
  }
  .ocl-table-standard tbody .operations >i:hover{
    transform: scale(1.2);
    color: #333;
  }




.ocl-list
 {
  margin: 40px 0;
  background: #8ecfed;
  padding: 20px 10px;
  border-radius: 5px;
}

/* Title styling for each section */
.ocl-list h2
{
  text-align: center;
  margin-bottom: 20px;
}


.ocl-list ul
 {
  list-style: none;
  padding: 0;
  margin: 0;
}

.ocl-list li
 {
  background-color: #ffffff;
  padding: 10px;
  margin: 10px 20px 10px 10px;
  border-radius: 12px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
}

.addpadding{
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
  flex-direction: column;

 }
 .addedPadding{
  display: flex;
  gap: 35px;
  flex-wrap: wrap;
  flex-direction: column;
 }
 .addedPadding div{
  display: flex;
  align-items: center;
 }

 .addedPadding p{
    /* margin-top: 16px; */
    margin-bottom: 0px;
    font-size: 12px;
    font-weight: 500;
    width: 207px;
 }